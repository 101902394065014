import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';

import WarningIcon from '@material-ui/icons/Warning';
import { Link } from 'react-router-dom';
import { Input } from '../../../../components/genericComponents/Input';
import { IconButton, Tooltip } from '@material-ui/core';
import { Loading } from '../../../utils/Loading';
import CloseIcon from '@material-ui/icons/Close';
import RerunAfterFailure from '../../listing/component/RerunAfterFailure';
import FillApprovalQuestions from './FillApprovalQuestions';
const SlidingDiv = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stripedRows] = useState(true);
  const [state, setState] = useState({ data: [], formData: {}, formError: {}, failed_stage_data: {} });
  const [servicesWillConitue, setServicesWillContinue] = useState([]);
  const pipeline_instance_id = props.pipeline_instance_id ? props.pipeline_instance_id : null;
  const pipeline_id = props.pipeline_id;
  const postFinalData = props.postFinalData ? props.postFinalData : () => { };
  const stage_instance = props.data ? props.data : [];
  const all_stages = props.all_stages ? props.all_stages : []
  console.log(all_stages, "all_stagesall_stages", stage_instance)
  const pipeline_data = props.pipeline_data ? props.pipeline_data : null;

  const onHandleOpenFillQuestions = props.onHandleOpenFillQuestions ? props.onHandleOpenFillQuestions : () => { }
  const failed_stage_instance = stage_instance[stage_instance.length - 1];

  const filterApprovalQuestionsStage = all_stages.find(item => item.id === failed_stage_instance.stage_id)
  const failed_task_instance = failed_stage_instance && failed_stage_instance.task_instance.find(item => item.status === "FAILED");
  console.log(failed_stage_instance, "pipeline_datapipeline_data", filterApprovalQuestionsStage)
  const continuePipelineFailure = props.continuePipelineFailure ? props.continuePipelineFailure : null;
  const rerunPipeline = props.rerunPipeline;
  const totalServices = props.totalServices;
  const [showTable, setShowTable] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);
  const pipeline_post_data = props.pipeline_post_data;
  console.log(failed_task_instance, "pipeline_post_data")
  const continueClicked = () => {
    setIsFlipped(true);
    setTimeout(() => {
      setShowTable(false);
      setIsFlipped(false);
    }, 500);
  };

  const backClicked = () => {
    setIsFlipped(true);
    setTimeout(() => {
      setShowTable(true);
      setIsFlipped(false);
    }, 500);
  };

  function closeDialogAndApiHit(data) {
    // toggleDiv()
    var post_data = {
      ...data,
      stage_instance_id: failed_stage_instance && failed_stage_instance.id,
      task_instance_id: failed_task_instance && failed_task_instance.id,
    }
    rerunPipeline(post_data);
    setIsOpen(!isOpen);
  }
  // useEffect(() => {
  //   if (isOpen) {
  //     const result_arr = totalServices.filter(item => !state.data.some(a => a.service_name === item.name));
  //     setServicesWillContinue(result_arr.map(item => (item.name)));
  //   }

  // }, [state.data]);

  function getServiceDetails() {
    var requestInfo = {
      endPoint: GenerateURL({
        pipeline_id: pipeline_id,
        pipeline_instance_id: pipeline_instance_id ? pipeline_instance_id : null,
        stage_instance_id: failed_stage_instance && failed_stage_instance.id,
        task_instance_id: failed_task_instance && failed_task_instance.id,
      }, properties.api.task_service_details),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    InvokeApi(requestInfo, handleResponse, handleError);
    
    setState(new_state => ({
      ...new_state,
      loaded: false
    }));
  }

  function handleResponse(data) {
    console.log(data,"abcdsbcgsdhbvg")
    setState(new_state => ({
      ...new_state,
      loaded: true,
      data: renderTableComponent(data)
    }));
  }



  function handleError(error) {
    console.log(error, "findingerrpr")
    setState({
      ...state,
      loaded: true,
      error: error
    });
  }
  const renderTableComponent = (data) => {
    console.log(data, "fdsadfsafsa")
    var table_data = [];
    if (data) {

      if (data.component_task_instance && data.component_task_instance.length > 0) {
        var filterFailedTask = data.component_task_instance.filter(item => item.activity_status.status === "FAILED");
        var filterSuccessServices = data.component_task_instance.filter(item => item.activity_status.status === "SUCCESS");
        // Assuming setServicesWillContinue is defined somewhere in your code
      setServicesWillContinue(filterSuccessServices.map(item => item.component.name));

      var uniqueRowData = filterFailedTask.reduce((uniqueTasks, item) => {
        const existingTask = uniqueTasks.find(task =>
          task.service_name === item.component.name &&
          task.env_name === (data.project_environment ? data.project_environment : null) &&
          task.branch_name === (item.information && item.information.build_args_key_value ? item.information.build_args_key_value.branch_name : null) &&
          task.status === item.status
        );

        if (!existingTask || existingTask.task_type === item.task_type) {
          uniqueTasks.push({
            "service_name": item.component && item.component.name ? item.component.name : null,
            "env_name": data.project_environment ? data.project_environment : null,
            "branch_name": item.information && item.information.build_args_key_value ? item.information.build_args_key_value.branch_name : null,
            "status": item.status,
            "logs_url": `${item.global_task_id}&service_name=${item.component && item.component.name}&service_env=${data.project_environment}&status=${item.status}&tab_id=${item.id}`,
            "task_type": failed_task_instance.task_type  // Corrected to use item.task_type
          });
        }

        return uniqueTasks;
      }, []);

      table_data = uniqueRowData;

      } else {
        if (data.jira_integration_task_instance && data.jira_integration_task_instance.length > 0) {
          var filterFailedTask = data.jira_integration_task_instance.filter(item => item.status === "FAILED");

          var single_row_data = filterFailedTask.map(item => {
            return {
              "status": item.status,
              "issue_type": item?.jira_task_details?.issuetype,
              "issue_key": item?.jira_task_details?.issue_key,
              "operation": item?.jira_task_details?.operation,
              "logs_url": `${item.global_task_id}&status=${item.status}&tab_id=${item.id}`,
              "task_type": failed_task_instance.task_type
            }
          })
          table_data = single_row_data
        } else {
          if (data.rest_api_task_instance && data.rest_api_task_instance.length > 0) {
            var filterFailedTask = data.rest_api_task_instance.filter(item => item.status === "FAILED");
            var single_row_data = filterFailedTask.map(item => {
              return {
                "status": item.status,
                "logs_url": `${item.global_task_id}&status=${item.status}&tab_id=${item.id}`,
                "task_type": failed_task_instance.task_type,
                "method": item.rest_api_task_details && item.rest_api_task_details.method ? item.rest_api_task_details.method : "N/A",
                "timeout": item.rest_api_task_details && item.rest_api_task_details.request_timeout ? item.rest_api_task_details.request_timeout : "N/A",
                "url": item.rest_api_task_details && item.rest_api_task_details.url ? item.rest_api_task_details.url : "N/A",
              }
            })
            table_data = single_row_data
          }

        }
      }
      return table_data;
    }
  }

  const toggleDiv = () => {
    console.log(state, "usyguggsdv")
    if (isOpen) {
      setIsOpen(!isOpen);

    } else {
      setIsOpen(!isOpen);
      if (failed_stage_instance && (failed_stage_instance.stageinstanceapproval && failed_stage_instance.stageinstanceapproval != null)) {
        console.log(state, "usyguggsdv")
        setState(new_state => ({
          ...new_state,
          failed_stage_data: failed_stage_instance,
          loaded: true
        }));

      } else {
        console.log(state, "usyguggsdv")
        getServiceDetails();
        console.log(state, "usyguggsdv")
      }

      console.log("close state")
    }
    backClicked()
  };
  function onChangeHandler(e) {
    var key = e.target.name;
    var value = e.target.value;

    setState({
      ...state,
      formData: {
        ...state.formData,
        [key]: value,
      },
      formError: {
        ...state.formError,
        [key]: null,
      }
    });
  }


  const postContinuePipelineData = () => {
    var find_task_type = state.data && state.data[0] && state.data[0].task_type;
    console.log(find_task_type, "find_task_type");
    var post_data = {}
    if (find_task_type == "BUILD" || find_task_type == "DEPLOY" || find_task_type == "PROMOTE") {

      post_data = {
        "stage_instance_id": failed_stage_instance && failed_stage_instance.id,
        "task_instance_id": failed_task_instance && failed_task_instance.id,
        "components": servicesWillConitue
      }
    } else {
      if (find_task_type == "JIRA_INTEGRATION") {
        var ticket_key = state.data.map(item => { return item.issue_key });
        ticket_key = ticket_key && ticket_key[0];
        console.log(state.formData, "task_type")
        post_data = {
          ...state.formData,
          "stage_instance_id": failed_stage_instance && failed_stage_instance.id,
          "task_instance_id": failed_task_instance && failed_task_instance.id,
          // [ticket_key]: 
        }
      } else {
        if (find_task_type == "REST_API") {
          var ticket_key = state.data.map(item => { return item.issue_key });
          ticket_key = ticket_key && ticket_key[0];
          console.log(state.formData, "task_type")
          post_data = {}
        }
      }
    }
    continuePipelineFailure(post_data)
    toggleDiv()
  }
  console.log(state.failed_stage_data, "ffdsf___safsaf")

  const retriggerApproval = () => {
    props.retriggerApprovalFn()
  }
  const handleRerunPipeline = () => {
    rerunPipeline();
  }

  return (
    <>{
      failed_stage_instance && failed_stage_instance.status == "FAILED" &&
      <>
        <button className='btn btn-danger-v2 btn-posi-bottom d-flex align-center' onClick={toggleDiv}>
          <WarningIcon />&nbsp;<span>{"Manage Failures BETA"} </span>
          {/* <span className='chip-default-blue' style={{position:'absolute', top:'-19px', right:'-11px', font:'11px', }}>BETA</span> */}

        </button>

        <div className={`drawer-div ${isOpen ? 'open border-none' : ''}`}>
          <div className="content">
            <div className='content-header d-flex align-center space-between'>
              <div className='d-flex align-center'>
                <img src='/images/bp-chatbot.svg' alt=".." />
                {
                  state.failed_stage_data && Object.keys(state.failed_stage_data).length > 0 ?
                    <p>Stage has failed with following details : {state?.failed_stage_data?.name}</p>
                    :
                    <p>Job has failed with following details : {failed_task_instance && failed_task_instance.task_name ? failed_task_instance.task_name : "N/A"}</p>
                }


              </div>
              <div>
                <IconButton onClick={toggleDiv}>
                  <CloseIcon style={{ color: "#fff", fontSize: '18px' }} />
                </IconButton>
              </div>
            </div>
            <div className='pd-20 content-body '>
              {!state.loaded ?
                <Loading varient="light" />
                :
                state.error ?
                  
                    <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                      <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} />
                      <p className="font-12 text-center">
                        {typeof (state.error) == "string" ?
                          state.error : state.error.toString()
                        }
                      </p>
                      <p className="font-12 text-center">Something went wrong. please contact to the Super Admin</p>
                    </div>
                  
                  :
                  state.loaded ?

                    <>
                      {
                        state.failed_stage_data && Object.keys(state.failed_stage_data).length > 0 ?

                          <div className={`flip-container ${isFlipped ? 'flip' : ''}`}>
                            <div className="flipper">
                              <p>Stage Approval has failed.</p>
                              <div className={`tableDivi ${showTable ? 'content-table' : 'hidden'}`}>
                                <div className="tableDiv-container">
                                  <table className={`tableDiv `} >

                                    <thead>

                                      <tr>
                                        <th>Stage</th>
                                        <th>Status</th>
                                        <th>Logs</th>
                                      </tr>
                                    </thead>
                                    <tr>
                                      <th className='table-sub-header' colSpan={4}>Execution Details for failed stage </th>
                                    </tr>
                                    <tbody>
                                      <tr>
                                        <td>{state.failed_stage_data.name}</td>
                                        <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{state.failed_stage_data.status}</span></div></td>

                                        <td>N/A</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          
                            <div className={`flip-container ${isFlipped ? 'flip' : ''}`}>
                              <div className="flipper">
                                {
                                  state.data && state.data[0] ?
                                    state.data[0].task_type ?
                                      state.data[0].task_type == "BUILD" || state.data[0].task_type == "DEPLOY" ||
                                        state.data[0].task_type == "PROMOTE" || state.data[0].task_type == "JIRA_INTEGRATION" || state.data[0].task_type == "REST_API" ?
                                        <div className={`tableDivi ${showTable ? 'content-table' : 'hidden'}`}>
                                          <div className="tableDiv-container">
                                            <table className={`tableDiv `} >

                                              <thead>

                                                {
                                                  state.data && state.data[0] &&
                                                    state.data[0].task_type && state.data[0].task_type === "BUILD" ?
                                                    <tr>
                                                      <th>Service</th>
                                                      <th>Env</th>
                                                      <th>Branch</th>
                                                      <th>Status</th>
                                                      <th>Logs</th>
                                                    </tr>
                                                    :
                                                    state.data[0].task_type === "DEPLOY" ?

                                                      <>
                                                        <tr>
                                                          <th className='table-sub-header' colSpan={4}>Execution Details for Deploy Job</th>
                                                        </tr>
                                                        <tr>
                                                          <th>Service</th>
                                                          <th>Env</th>
                                                          <th>Status</th>
                                                          <th>Logs</th>
                                                        </tr></>
                                                      :
                                                      state.data[0].task_type === "PROMOTE" ?
                                                        <tr>
                                                          <th>Service</th>
                                                          <th>Source Env</th>
                                                          <th>Target Env</th>
                                                          <th>Status</th>
                                                          <th>Logs</th>
                                                        </tr> :
                                                        state.data[0].task_type === "JIRA_INTEGRATION" ?
                                                          <tr>
                                                            <th>Operation</th>
                                                            <th>Issue Type</th>
                                                            <th>Issue Key</th>
                                                            <th>Status</th>
                                                            <th>Logs</th>
                                                          </tr>
                                                          :
                                                          state.data[0].task_type === "REST_API" ?
                                                            <tr>
                                                              <th>Method</th>
                                                              <th>URL</th>
                                                              <th>Timeout</th>
                                                              <th>Status</th>
                                                              <th>Logs</th>
                                                            </tr>
                                                            :
                                                            null

                                                }
                                              </thead>
                                              <tbody>
                                                {console.log(state.data, "Fsdfdsafsafsa")}
                                                {
                                                  state.data.map((item, index) => (
                                                    item.task_type == "BUILD" ?
                                                      <tr className={stripedRows && index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                        <td>{item.service_name ? item.service_name : "N/A"}</td>
                                                        <td>{item.env_name ? item.env_name : "N/A"}</td>
                                                        <td>{item.branch_name ? item.branch_name : "N/A"}</td>
                                                        <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{item.status ? item.status : "N/A"}</span></div></td>
                                                        <td><Link to={`/logs?global_task_id=${item.logs_url}`} target="_blank" className='text-anchor-blue '>View Logs</Link></td>
                                                      </tr>
                                                      :
                                                      item.task_type == "DEPLOY" ?
                                                        <tr className={stripedRows && index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                          {console.log(item, "fdasfdsfa")}
                                                          <td>{item.service_name ? item.service_name : "N/A"}</td>
                                                          <td>{item.env_name ? item.env_name : "N/A"}</td>
                                                          <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{item.status ? item.status : "N/A"}</span></div></td>
                                                          <td><Link to={`/logs?global_task_id=${item.logs_url}`} target="_blank" className='text-anchor-blue '>View Logs</Link></td>
                                                        </tr>
                                                        :
                                                        item.task_type == "PROMOTE" ?
                                                          <tr className={stripedRows && index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                            <td>{item.service_name ? item.service_name : "N/A"}</td>
                                                            <td>{item.env_name ? item.env_name : "N/A"}</td>
                                                            <td>{item.branch_name ? item.branch_name : "N/A"}</td>
                                                            <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{item.status ? item.status : "N/A"}</span></div></td>
                                                            <td><Link to={`/logs?global_task_id=${item.logs_url}`} target="_blank" className='text-anchor-blue '>View Logs</Link></td>
                                                          </tr>
                                                          :
                                                          item.task_type == "JIRA_INTEGRATION" ?
                                                            <tr className={stripedRows && index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                              <td>{item.operation ? item.operation : "N/A"}</td>
                                                              <td>{item.issue_type ? item.issue_type : "N/A"}</td>
                                                              <td><Tooltip title={item.issue_key}>
                                                                <p className='text-ellipsis-80'>{item.issue_key ? item.issue_key : "N/A"}</p>
                                                              </Tooltip></td>

                                                              <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{item.status ? item.status : "N/A"}</span></div></td>
                                                              <td><Link to={`/logs?global_task_id=${item.logs_url}`} target="_blank" className='text-anchor-blue '>View Logs</Link></td>
                                                            </tr>
                                                            :
                                                            item.task_type == "REST_API" ?
                                                              <tr className={stripedRows && index % 2 === 0 ? 'even-row' : 'odd-row'}>

                                                                <td>{item.method ? item.method : "N/A"}</td>
                                                                <td><Tooltip title={item.url}>
                                                                  <p className='text-ellipsis-80'>{item.url ? item.url : "N/A"}</p>
                                                                </Tooltip></td>
                                                                <td>{item.timeout ? item.timeout : "N/A"}</td>


                                                                <td><div className='d-flex align-center'><span className='status-bullet status-bullet-danger'></span><span className='color-danger text-transform-lower-case'>{item.status ? item.status : "N/A"}</span></div></td>
                                                                <td><Link to={`/logs?global_task_id=${item.logs_url}`} target="_blank" className='text-anchor-blue '>View Logs</Link></td>
                                                              </tr>
                                                              :
                                                              null
                                                  ))
                                                }
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className='pd-20 text-center mt-20 mb-20 highlighted-box' style={state.error && !state.loaded ? { borderRadius: '8px', position: 'relative', top: '0px', backgroundColor: 'rgb(18 77 155)' } : { borderRadius: '8px', position: 'relative', backgroundColor: 'rgb(18 77 155)' }}>
                                            <p className="text-align-left">
                                              <p className='mb-20'><b>Please note: </b> that on pipeline failure, there are two ways of recovering from failure.</p>
                                              <p>1. Re-run the failed job</p>
                                              <p>2. Continue to next job with failure</p>
                                            </p>
                                          </div>
                                        </div>
                                        : null : null : null
                                }
                                <div className={`messageDiv ${showTable ? 'hidden' : ''}`}>


                                  {
                                    state.data && state.data[0] &&
                                      state.data[0].task_type && state.data[0].task_type === "BUILD" ?
                                      <div className='div-structure'>
                                        <p>Continue with failures:</p>
                                        <p><b>Please note:</b> pipeline will skip execution for following microservices:&nbsp;
                                          {state.data.map((item, index) => (
                                            <span key={index} className='chip chip-failed'>
                                              {item.service_name}
                                              {/* {index !== state.data.length - 1 && ', '} */}
                                            </span>
                                          ))}
                                        </p>
                                        <p><b>On continue:</b> pipeline will proceed with following microservices:&nbsp;
                                          {servicesWillConitue.map((item, index) => (
                                            <span key={index} className='chip chip-success'>
                                              {item}
                                              {/* {index !== servicesWillConitue.length - 1 && ', '} */}
                                            </span>
                                          ))}
                                        </p>


                                      </div> :
                                      state.data[0]?.task_type === "DEPLOY" ?
                                        
                                          <div className='div-structure'>
                                            <p>Continue with failures:</p>
                                            <p><b>Please note:</b> pipeline will skip execution for following microservices:&nbsp;
                                              {state.data.map((item, index) => (
                                                <span key={index} className='chip chip-failed'>
                                                  {item.service_name}
                                                  {/* {index !== state.data.length - 1 && ', '} */}
                                                </span>
                                              ))}
                                            </p>
                                            <p><b>On continue:</b> pipeline will proceed with following microservices:&nbsp;
                                              {servicesWillConitue.map((item, index) => (
                                                <span key={index} className='chip chip-success'>
                                                  {item}
                                                  {/* {index !== servicesWillConitue.length - 1 && ', '} */}
                                                </span>
                                              ))}
                                            </p>


                                          </div>
                                        
                                        :
                                        state.data[0]?.task_type === "PROMOTE" ?
                                          
                                            
                                              <div className='div-structure'>
                                                <p>Continue with failures:</p>
                                                <p><b>Please note:</b> pipeline will skip execution for following microservices:&nbsp;
                                                  {state.data.map((item, index) => (
                                                    <span key={index} className='chip chip-failed'>
                                                      {item.service_name}
                                                      {index !== state.data.length - 1 && ', '}
                                                    </span>
                                                  ))}
                                                </p>

                                                <p><b>On continue:</b> pipeline will proceed with following microservices:&nbsp;
                                                  {/* {servicesWillConitue.join(', ')} */}
                                                  {servicesWillConitue.map((item, index) => (
                                                    <span key={index} className='chip chip-success'>
                                                      {item}
                                                      {index !== servicesWillConitue.length - 1 && ', '}
                                                    </span>
                                                  ))}
                                                </p>


                                              </div>
                                            
                                           :
                                          state.data[0]?.task_type === "JIRA_INTEGRATION" ?
                                            
                                              <div className='jira-integration-flow'>
                                                <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                                                  {/* <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} /> */}
                                                  <p className="font-12 text-center mb-20">
                                                    {
                                                      state.data[0].operation && state.data[0].operation == "create" ?
                                                        <><b>Please Note:</b> Your Jira ticket creation has failed, if you want to continue please create the Jira ticket manually and enter the details after clicking the continue.</>
                                                        :
                                                        state.data[0].operation && state.data[0].operation == "update" ?
                                                          <><b>Please Note:</b> Your Jira ticket comment update has failed, if you want to continue please update the comment manually on the jira ticket Jira Ticket ID and click continue.</>
                                                          :
                                                          <><b>Please Note:</b> Your Jira ticket status transition has failed, if you want to continue please update the Jira ticket status manually on the jira ticket Jira Ticket ID and click continue.</>
                                                    }

                                                  </p>
                                                  {
                                                    state.data[0].operation && state.data[0].operation == "create" &&
                                                    <Input
                                                      type="text"
                                                      data={state.formData}
                                                      error={state.formError}
                                                      onChangeHandler={onChangeHandler}
                                                      placeholder="ot-961"
                                                      mandatorySign
                                                      label={state.data[0].operation == "create" ? "Enter Jira Ticket" : state.data[0].operation == "update" ? "Enter Comment" : "Enter Jira Ticket"}
                                                      name={state.data[0].issue_key}
                                                    />
                                                  }

                                                </div>
                                              </div> :
                                            state.data[0]?.task_type === "INTEGRATION" ?
                                              
                                                <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                                                  <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} />
                                                  <p className="font-12 text-center">
                                                    <b>Please Note:</b> Your Integration testing response has failed, do you still want to continue?
                                                  </p>
                                                </div> :
                                              state.data[0].task_type === "REST_API" ?
                                                
                                                  <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                                                    <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} />
                                                    <p className="font-12 text-center">
                                                      <b>Please Note:</b> Your API call has failed, do you want to still continue?
                                                    </p>
                                                  </div> :
                                                state.data[0].task_type === "ROLLBACK" ?
                                                  
                                                    <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                                                      <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} />
                                                      <p className="font-12 text-center">
                                                        <b>Please Note:</b> Rollback is failed. please re trigger the pipeline.
                                                      </p>
                                                    </div> :
                                                  null
                                  }
                                </div>
                              </div>
                            </div>

                          
                      }

                    </>
                    :
                    <div className='pd-20 text-center mt-20 mb-20' style={{ backgroundColor: '#F8F8F8', borderRadius: '8px' }}>
                      <ErrorOutlineIcon className='text-center' style={{ color: '#e9797e', textAlign: 'center', fontSize: '40px' }} />
                      <p className="font-12 text-center">
                        Unable to load data. please contact to the Super Admin
                      </p>
                    </div>
              }
            </div>

            {
              !showTable ?
                <div className='pd-10 content-footer space-between d-flex align-center'>
                  <button className="btn btn-outline-dark-grey" onClick={backClicked}>
                    Back
                  </button>
                  <button className="btn btn-primary-v2" onClick={postContinuePipelineData}>
                    Continue
                  </button>
                </div> :
                <div className='pd-10 content-footer space-between d-flex align-center'>
                  <button className="btn btn-outline-dark-grey" onClick={toggleDiv}>
                    Cancel
                  </button>
                  {
                    state.error && !state.loaded ? null :
                      <div className='justify-end d-flex align-center'>
                        {
                          state.data && state.data.length > 0 ?
                            <RerunAfterFailure
                              pipeline={pipeline_data}
                              data={state && state.data}
                              rerunJob={closeDialogAndApiHit} />
                            :
                            state.failed_stage_data && Object.keys(state.failed_stage_data).length != 0 ?
                              
                                <FillApprovalQuestions
                                  stage_instance_id={failed_stage_instance && failed_stage_instance.id}
                                  pipeline_id={pipeline_id ? pipeline_id : ""}
                                  pipeline_instance_id={pipeline_instance_id ? pipeline_instance_id : ""}
                                  postFinalData={postFinalData}
                                  stage_name={failed_stage_instance.name}
                                  btnVariant="re_attempt"
                                  stage_instance_status={failed_stage_instance && failed_stage_instance.status}
                                  questionnaires={filterApprovalQuestionsStage && filterApprovalQuestionsStage.questionnaires}
                                />
                              

                              : null
                        }

                        {/* <button className="btn btn-primary-v2" onClick={rerunPipeline}>
                          Rerun Failed Job
                        </button> */}
                        {
                          state.failed_stage_data && Object.keys(state.failed_stage_data).length != 0 ?
                            <></> :
                            <button className="btn btn-primary-v2" onClick={continueClicked}>
                              Continue with failures
                            </button>
                        }

                      </div>
                  }

                </div>
            }

          </div>
        </div>
      </>
    }

    </>

  );
};

SlidingDiv.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
};

export default SlidingDiv;
